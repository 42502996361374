import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PostEntry from "../../components/post-entry"
import Pagination from "../../components/pagination"
import { Container } from "reactstrap"
import { Section } from "../../components/Interface"
import constants from "../../../utils/constants"

const BlogArchive = ({ pageContext: { nodes, pageNumber, hasNextPage } }) => (
  <Layout>
    <SEO
      title={`${constants.BRAND_SHORTNAME} Blog`}
      description="Our Sewing Blog covers sewing topics, sewing instructions, sewing, embroidery, and quilting machines."
    />
    <Container>
      <h1 class="section-header">{`${constants.BRAND_SHORTNAME} Blog`}</h1>
      <div id="blog">
        {nodes && nodes.map(post => <PostEntry key={post.id} post={post} />)}
      </div>

      <Section>
        <Pagination
          pageNumber={pageNumber}
          hasNextPage={hasNextPage}
          path="/blog"
        />
      </Section>
    </Container>
  </Layout>
)

export default BlogArchive
