import React from "react";
import { Link } from "gatsby"
import { Pagination, PaginationItem, PaginationLink, Col } from "reactstrap";
import { Inner } from '../components/Interface'

const renderPreviousLink = (pageNumber, path) => {
  let previousLink = null

  if (!pageNumber || pageNumber === 1) {
    return null
  } else if (2 === pageNumber) {
    previousLink = `${path}`
  } else if (2 < pageNumber) {
    previousLink = `${path}/${pageNumber - 1}`
  }

  return (
    <Link to={previousLink}>
      <PaginationItem>
        <PaginationLink previous />
      </PaginationItem>
    </Link>
  )
}

const renderNextLink = (hasNextPage, pageNumber, path) => {
  if (hasNextPage) {
    return (
      <Link to={`${path}/${pageNumber + 1}`}>
        <PaginationItem>
          <PaginationLink next />
        </PaginationItem>
      </Link>
    )
  } else {
    return null
  }
}

const CustomPagination = ({ pageNumber, hasNextPage, path }) => (
  <Col md="12" className="d-flex justify-content-center">
    <Inner>
      <Pagination size="lg" aria-label="Page navigation example">
        {renderPreviousLink(pageNumber, path)}
        <PaginationItem active>
          <PaginationLink>{pageNumber}</PaginationLink>
        </PaginationItem>
        {renderNextLink(hasNextPage, pageNumber, path)}
      </Pagination>
    </Inner>
  </Col>
);

export default CustomPagination;
